import GuidUtils from '@/shared/utils/guid'

export default class SocialAttendanceType {
  constructor({
    id,
    name
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name
  }
}